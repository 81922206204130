import React from "react"
import { Link, graphql } from "gatsby"
import Carousel from "react-bootstrap/Carousel"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OpenTimes from "../components/opentimes"

import "./index.scss"
import { buildImageObj, imageUrlFor } from "../lib/image"
import BlockContent from "../components/block-content"

const IndexPage = ({ data: { sanityHomePageSettings: data } }) => (
  <Layout page="index">
    <SEO title="Home" disableTitle />
    <div className="main-carousel">
      <Carousel indicators={false} controls={true} fade>
        {data.sliderImages.map(image => (
          <Carousel.Item
            key={image.asset._id}
            style={{
              backgroundImage: `url('${imageUrlFor(buildImageObj(image))
                .width(700)
                .height(400)
                .url()}')`,
            }}
          >
            <img
              style={{ display: "none", visibility: "hidden" }}
              src={imageUrlFor(buildImageObj(image))
                .width(700)
                .height(400)
                .url()}
              alt="Slider Bild"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
    <Row>
      <Col xs={12} className="main-content-col">
        <div className="display-header">
          <h2>{data.heading}</h2>
        </div>
        <div>
          <BlockContent blocks={data._rawBody} />
        </div>
      </Col>
      <Col xs={12} className="main-content-col">
        <hr />
        <div className="display-header">
          <h2>Öffnungszeiten</h2>
        </div>
        <OpenTimes />
        <Link to="/kontakt" className="contact-button">
          <i className="fas fa-link mr-2"></i> Kontakt und Anfahrt
        </Link>
      </Col>
    </Row>
  </Layout>
)

export const pageQuery = graphql`
  query HomePageSettingsQuery {
    sanityHomePageSettings(_id: { eq: "homePageSettings" }) {
      title
      sliderImages {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      heading
      _rawBody
    }
  }
`

export default IndexPage
